import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 720.000000 720.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,720.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M3620 5560 c-19 -5 -53 -13 -75 -18 -22 -6 -60 -13 -85 -16 -65 -8
-116 -24 -130 -41 -7 -8 -17 -15 -22 -15 -5 0 -28 -9 -50 -20 -23 -11 -56 -20
-73 -20 -128 0 -297 -100 -328 -194 -11 -34 -22 -45 -63 -66 -61 -31 -124 -93
-147 -145 -28 -61 -48 -97 -73 -127 -42 -52 -66 -135 -62 -219 2 -62 9 -88 40
-150 20 -40 46 -84 57 -95 11 -12 23 -39 27 -59 l7 -38 -34 7 c-84 16 -129 18
-129 7 0 -58 73 -213 120 -256 26 -23 25 -35 -1 -35 -11 0 -17 -4 -14 -10 3
-5 18 -7 34 -4 18 4 42 -1 67 -14 l39 -20 -28 -11 c-16 -6 -37 -11 -47 -11
-30 0 -31 -15 -4 -39 14 -12 23 -26 19 -29 -4 -4 -13 2 -21 12 -24 33 -91 30
-160 -8 -32 -18 -59 -39 -59 -47 0 -8 -8 -27 -19 -42 -10 -15 -23 -49 -27 -75
-5 -26 -14 -49 -19 -50 -13 -5 -13 -39 0 -47 5 -3 6 -13 3 -22 -4 -10 -2 -14
4 -10 6 4 13 18 16 32 3 20 1 25 -11 20 -12 -4 -13 -3 -2 10 9 11 16 12 26 4
17 -14 35 -3 27 16 -3 9 1 15 11 15 9 0 16 5 16 11 0 6 -7 8 -16 5 -25 -10
-33 2 -21 33 10 26 14 29 46 24 45 -7 44 -6 21 -31 -12 -12 -17 -22 -13 -22
19 0 93 46 93 57 0 7 10 13 23 13 13 0 28 5 34 11 7 7 31 12 55 12 34 0 38 1
20 8 -32 12 -27 22 8 17 22 -3 30 0 30 10 0 10 6 12 23 7 12 -3 40 -9 62 -11
22 -3 46 -7 53 -9 21 -8 13 -25 -12 -25 -14 0 -28 -4 -31 -10 -3 -5 -16 -10
-29 -10 -25 0 -82 -26 -92 -41 -9 -15 4 -23 18 -12 30 24 277 36 263 13 -3 -5
-2 -10 4 -10 5 0 11 6 13 12 3 9 27 5 89 -15 143 -47 203 -66 239 -73 19 -3
43 -13 53 -22 12 -10 17 -11 17 -3 0 9 36 12 146 9 l145 -3 -5 -29 c-5 -25 -4
-27 9 -16 9 7 13 21 10 31 -5 16 -1 19 22 19 20 0 33 -8 45 -27 11 -18 18 -22
19 -13 1 8 2 22 3 30 2 24 70 24 143 -1 87 -28 156 -23 214 16 54 38 213 197
298 299 141 167 202 289 244 481 34 158 52 454 32 530 -13 47 -63 117 -108
150 -130 93 -199 125 -281 133 -71 6 -112 30 -155 89 -71 98 -210 176 -341
192 -96 12 -169 12 -220 1z m133 -7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0
19 -2 13 -5z m-63 -13 c8 -5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -5
10 6 0 17 -5 25 -10z m75 -40 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8
10 10 10 2 0 7 -4 10 -10z m640 -280 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0
6 2 10 4 10 3 0 8 -4 11 -10z m-1892 -1344 c12 -3 25 0 29 7 4 6 10 8 14 4 4
-4 -3 -17 -16 -29 -16 -15 -25 -18 -28 -10 -2 7 -9 12 -15 12 -8 0 -7 -4 1
-12 14 -14 16 -28 3 -28 -13 0 -28 51 -22 75 4 16 5 17 8 4 2 -10 14 -19 26
-23z m-66 -28 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-47 -118
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M2093 4508 c-22 -18 -55 -44 -72 -58 -17 -14 -31 -30 -31 -36 0 -7
-10 -14 -22 -18 -17 -4 -20 -8 -12 -16 13 -13 -2 -78 -21 -93 -7 -6 -6 -10 3
-14 6 -2 12 -11 12 -18 0 -7 8 -28 17 -47 9 -18 19 -40 23 -48 8 -16 93 -33
123 -24 9 3 24 18 32 34 14 27 13 30 -15 54 -29 24 -30 26 -19 76 12 54 41 80
93 80 31 0 57 10 51 19 -2 4 5 7 17 7 32 -1 43 25 27 62 -8 18 -19 32 -24 32
-6 0 -16 5 -23 12 -13 13 -28 17 -83 25 -29 4 -42 -1 -76 -29z m40 -79 c-10
-17 -26 -37 -35 -43 -10 -6 -18 -15 -18 -21 0 -6 -11 -19 -25 -29 -19 -14 -22
-22 -15 -32 8 -8 8 -14 0 -19 -7 -4 -7 -12 0 -26 9 -16 7 -19 -12 -19 -13 0
-31 7 -41 14 -15 12 -16 14 -2 20 9 3 15 18 15 40 0 19 5 38 10 41 8 5 8 11 0
20 -13 15 11 28 35 19 8 -4 15 -1 15 5 0 6 -8 11 -17 12 -14 0 -13 2 5 9 12 5
22 14 22 19 0 10 22 18 58 20 23 1 23 1 5 -30z"/>
<path d="M4828 4243 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4980 4232 c0 -5 7 -13 16 -16 23 -9 5 -29 -22 -23 -21 4 -22 2 -12
-17 l11 -21 -24 21 c-17 15 -31 19 -49 14 -14 -3 -50 -1 -80 6 -68 16 -80 17
-80 4 0 -5 10 -10 23 -10 65 0 257 -84 360 -156 26 -19 50 -34 53 -34 7 0 85
-63 138 -112 41 -38 40 -52 -2 -22 -17 12 -22 12 -22 2 0 -10 -2 -10 -9 1 -5
8 -26 20 -46 27 l-37 12 6 -29 c3 -16 9 -42 12 -59 9 -41 -7 -38 -31 5 -10 20
-24 34 -31 31 -7 -3 -18 2 -25 10 -12 14 -14 14 -25 -1 -8 -10 -20 -15 -29
-12 -9 4 -15 1 -15 -9 0 -11 6 -15 20 -11 24 6 26 -6 3 -24 -13 -11 -17 -11
-20 -1 -3 6 -10 12 -17 12 -13 0 -46 35 -46 50 0 6 -7 10 -15 10 -8 0 -15 -7
-15 -16 0 -11 -5 -14 -16 -10 -9 4 -32 4 -50 0 -24 -4 -31 -10 -27 -22 5 -11
2 -14 -10 -9 -46 19 -50 18 -25 -1 16 -14 33 -20 45 -16 12 4 14 3 5 -3 -16
-12 88 -117 133 -134 57 -21 80 -34 113 -65 34 -32 150 -94 175 -94 7 0 47
-11 87 -25 40 -14 92 -25 114 -25 23 -1 78 -13 124 -29 45 -15 82 -26 82 -24
0 2 -17 41 -39 86 -21 45 -51 118 -65 162 -15 44 -34 100 -42 125 -9 25 -24
73 -34 108 -11 35 -34 87 -52 115 -19 29 -39 64 -45 78 -7 16 -32 36 -64 52
-30 15 -46 27 -35 27 16 0 15 2 -5 19 -24 19 -50 18 -31 -1 7 -7 6 -15 -3 -25
-18 -17 -64 -7 -85 20 -13 15 -29 19 -80 20 -44 0 -57 3 -42 8 12 5 22 10 22
11 0 2 -24 3 -54 4 -30 0 -61 6 -70 13 -9 8 -16 9 -16 3z m-50 -396 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m164 -92 c9
-24 2 -26 -12 -4 -7 11 -8 20 -3 20 5 0 12 -7 15 -16z"/>
<path d="M5225 4230 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2487 4009 c-44 -50 -33 -76 18 -43 27 18 34 40 11 31 -10 -4 -13 -1
-9 9 4 11 8 12 20 3 11 -10 18 -9 31 5 16 17 16 18 -7 11 -14 -4 -26 -3 -30 4
-4 6 -17 -1 -34 -20z"/>
<path d="M4680 3941 l-25 -7 25 -17 c34 -22 89 -41 97 -33 4 3 -1 6 -10 6 -9
0 -17 5 -17 10 0 6 6 10 13 10 8 0 7 4 -3 10 -19 12 -43 13 -36 1 3 -4 -7 -6
-22 -2 -25 5 -25 6 -4 9 12 2 22 8 22 13 0 10 -4 10 -40 0z"/>
<path d="M2393 3933 c9 -2 14 -9 11 -15 -4 -7 -2 -8 4 -4 20 12 13 26 -10 25
-18 -1 -19 -2 -5 -6z"/>
<path d="M4788 3902 c8 -5 12 -17 10 -27 -2 -10 2 -20 9 -22 8 -3 9 4 4 23 -4
15 -14 29 -22 32 -11 3 -11 2 -1 -6z"/>
<path d="M2914 3764 c19 -6 18 -7 -4 -19 -16 -9 -33 -10 -54 -4 -26 8 -34 6
-47 -12 -15 -20 -16 -21 -23 -3 -7 18 -8 18 -21 0 -8 -10 -18 -15 -24 -11 -6
3 -11 1 -11 -4 0 -6 -9 -11 -20 -11 -13 0 -18 -5 -14 -15 4 -10 0 -15 -11 -15
-9 0 -13 5 -10 10 3 6 0 10 -8 10 -8 0 -17 -7 -21 -15 -3 -8 -14 -15 -24 -15
-11 0 -30 -8 -43 -18 -32 -24 -79 -43 -88 -37 -5 2 -18 -4 -30 -14 -16 -14
-19 -24 -13 -41 11 -28 -6 -68 -31 -76 -11 -3 -16 -12 -13 -20 3 -8 1 -14 -4
-14 -6 0 -10 -4 -10 -9 0 -5 10 -7 22 -3 21 5 21 4 5 -19 -9 -13 -17 -31 -17
-41 0 -13 11 -6 38 24 20 23 44 49 52 58 23 22 158 110 170 110 6 0 28 9 49
20 44 22 191 63 198 56 3 -3 -2 -12 -11 -21 -18 -18 -21 -30 -6 -20 6 3 10 -1
10 -11 0 -12 -6 -15 -21 -11 -12 3 -38 -5 -64 -21 -47 -29 -59 -48 -24 -39 18
5 20 2 15 -16 -3 -12 -6 -25 -6 -29 0 -4 -7 -5 -15 -2 -11 5 -14 2 -9 -12 6
-18 6 -18 -8 -1 -13 17 -15 17 -27 3 -8 -9 -24 -16 -37 -16 -12 0 -30 -8 -40
-17 -18 -18 -21 -20 -51 -22 -15 -1 -15 -3 3 -16 18 -13 17 -14 -17 -15 -23 0
-39 -6 -43 -15 -3 -8 -1 -15 4 -15 6 0 10 -4 10 -10 0 -5 -7 -10 -15 -10 -9 0
-12 -6 -9 -15 5 -11 -1 -15 -19 -15 -24 0 -25 -2 -15 -28 11 -28 12 -28 40
-15 15 8 28 18 28 23 0 16 44 51 58 45 7 -2 11 -1 8 3 -7 12 32 43 79 65 65
30 115 47 115 38 0 -15 -27 -31 -42 -26 -10 4 -17 -1 -21 -14 -3 -12 -13 -24
-24 -27 -10 -3 -25 -8 -33 -12 -8 -3 -20 -4 -27 -3 -13 2 -27 -17 -28 -39 0
-8 -8 -14 -17 -14 -10 1 -15 -2 -12 -7 9 -16 54 -10 54 7 0 21 34 14 38 -9 3
-13 -4 -17 -30 -18 l-33 -1 30 -8 30 -8 -30 -1 c-30 -1 -73 -21 -47 -21 9 0 7
-9 -8 -30 -24 -34 -36 -37 -62 -13 -17 15 -18 15 -18 -4 0 -14 6 -20 18 -19
30 2 19 -28 -13 -35 -23 -5 -25 -7 -7 -8 26 -1 29 -17 4 -25 -10 -3 -23 -20
-29 -37 -8 -20 -17 -30 -26 -26 -10 4 -13 -1 -9 -19 5 -26 32 -33 32 -8 0 17
103 124 120 124 5 0 10 -11 10 -25 0 -18 5 -25 19 -25 11 0 23 5 26 10 12 20
21 9 17 -20 -4 -27 -7 -29 -31 -23 -14 3 -31 13 -38 22 -6 9 -14 13 -18 9 -10
-10 4 -28 29 -38 16 -7 17 -9 4 -9 -20 -1 -31 -31 -22 -56 5 -12 4 -16 -3 -12
-6 4 -11 1 -10 -6 1 -33 -4 -47 -18 -47 -8 0 -15 -4 -15 -9 0 -5 -6 -8 -12 -7
-7 0 -14 -3 -15 -9 -2 -5 2 -10 9 -10 6 0 16 -15 20 -32 5 -18 24 -59 43 -90
19 -32 35 -64 35 -70 0 -7 7 -13 15 -13 9 0 20 -11 25 -25 13 -33 24 -32 49 8
25 40 66 80 87 83 8 1 23 12 33 23 10 12 25 21 32 21 8 0 30 10 49 22 19 12
43 21 52 19 9 -1 30 5 47 13 56 30 161 64 167 55 6 -11 84 30 84 44 0 5 11 6
25 3 17 -4 30 0 43 14 11 12 26 18 34 15 11 -4 25 6 44 30 15 20 32 34 39 32
6 -3 24 19 39 47 16 29 38 56 50 62 11 5 22 18 24 28 2 10 22 48 43 83 21 35
39 66 39 70 0 3 7 15 16 24 15 17 51 123 60 179 4 24 4 24 -7 -2 -13 -34 -29
-36 -30 -5 -1 20 -1 20 -10 -4 -10 -27 -49 -47 -49 -26 0 7 -11 12 -25 12 -19
0 -25 -5 -25 -22 0 -14 -6 -23 -15 -23 -14 0 -19 11 -16 37 1 7 -8 17 -19 23
-11 6 -18 15 -15 20 3 6 -1 7 -9 4 -11 -4 -14 0 -11 15 2 13 -1 21 -10 21 -26
0 -27 -21 -3 -53 14 -18 27 -40 31 -50 4 -10 12 -15 18 -11 8 5 8 1 0 -14 -15
-27 -14 -32 4 -32 10 0 15 -9 14 -27 0 -19 -3 -23 -7 -13 -8 17 -31 45 -79 99
-30 32 -33 33 -33 13 0 -16 6 -22 21 -22 11 0 18 -4 15 -9 -3 -4 1 -16 8 -26
14 -19 10 -55 -6 -55 -5 0 -6 4 -3 10 4 6 -1 7 -11 3 -13 -5 -16 -3 -11 10 8
22 1 21 -30 -3 -14 -11 -29 -17 -34 -14 -5 3 -4 -6 3 -20 6 -14 17 -26 23 -26
16 0 27 -38 13 -42 -6 -2 -11 -15 -12 -28 -1 -15 -8 -26 -18 -28 -19 -4 -23
10 -19 58 1 20 -1 22 -9 10 -5 -8 -10 -23 -10 -32 0 -10 -10 -28 -21 -39 -16
-16 -18 -23 -9 -29 8 -5 8 -10 2 -14 -5 -3 -13 2 -16 11 -5 12 -10 13 -18 5
-8 -8 -22 -2 -53 24 -23 18 -45 34 -49 34 -10 0 -7 -28 4 -32 19 -6 -4 -53
-27 -55 -13 -2 -23 2 -23 7 0 6 -7 10 -15 10 -8 0 -15 5 -15 11 0 6 9 9 20 6
11 -3 18 -1 14 4 -3 5 -14 9 -25 9 -10 0 -19 5 -19 10 0 6 -6 10 -14 10 -7 0
-25 11 -40 25 -15 14 -38 28 -51 31 -14 3 -25 10 -25 14 0 16 -65 18 -82 3
-16 -14 -20 -14 -43 1 -22 15 -25 22 -20 53 3 20 7 39 10 41 2 3 0 12 -5 22
-6 11 -3 25 10 45 10 15 16 34 13 41 -3 7 5 30 16 51 20 38 74 193 68 199 -1
2 -23 5 -47 8 -25 3 -36 3 -26 0z m-64 -244 c0 -5 -7 -10 -16 -10 -8 0 -12 5
-9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m830 -80 c0 -5 -9 -6 -20 -3 -20 5 -25
15 -13 26 7 8 33 -11 33 -23z m70 6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0
6 5 11 10 11 6 0 10 -2 10 -4z m-90 -25 c-1 -3 -7 -17 -15 -31 l-15 -25 6 28
c4 17 2 27 -6 27 -6 0 -9 4 -5 10 6 10 35 3 35 -9z m-95 -61 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-965 -4 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m906 -33 c-10
-10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m71 -15 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-62 -8 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-630 -71 c10 -14 1 -21 -19 -13 -9 3
-13 10 -10 15 7 12 21 11 29 -2z m587 -42 c-16 -7 -26 -7 -35 2 -9 10 -8 11 6
6 9 -4 17 -2 17 4 0 6 7 7 17 4 14 -6 14 -8 -5 -16z m-582 5 c0 -4 -9 -13 -20
-20 -23 -14 -26 -8 -7 13 13 16 27 19 27 7z m-164 -54 c-12 -21 -62 -26 -50
-5 20 34 36 46 49 34 9 -9 9 -16 1 -29z m804 2 c0 -5 -7 -10 -16 -10 -8 0 -12
5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m-114 -55 c16 -8 22 -15 14 -15 -8 1
-24 7 -34 15 -25 19 -16 19 20 0z m-736 -85 c0 -5 -2 -10 -4 -10 -3 0 -8 5
-11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M3010 3710 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M3120 3651 c0 -13 28 -33 41 -29 6 2 0 12 -15 21 -14 10 -26 13 -26
8z"/>
<path d="M3780 3581 c0 -12 5 -21 10 -21 6 0 10 6 10 14 0 8 -4 18 -10 21 -5
3 -10 -3 -10 -14z"/>
<path d="M3254 3538 c10 -16 25 -28 33 -28 16 0 9 9 -31 40 -19 15 -19 14 -2
-12z"/>
<path d="M3680 3510 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3310 3496 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24 12z"/>
<path d="M3536 3467 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M3355 3450 c-8 -12 2 -30 17 -30 7 0 7 4 -1 13 -6 8 -8 17 -4 20 3 4
4 7 0 7 -3 0 -9 -4 -12 -10z"/>
<path d="M3025 3427 c13 -10 15 -16 6 -21 -6 -4 -9 -11 -6 -16 3 -5 14 -2 25
7 18 15 18 17 2 29 -9 8 -23 14 -31 14 -11 0 -10 -4 4 -13z"/>
<path d="M3060 3360 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M3100 3343 c0 -9 29 -33 39 -33 19 0 11 15 -14 27 -14 6 -25 9 -25 6z"/>
<path d="M2900 3336 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M2380 3272 c0 -6 5 -14 10 -17 6 -3 10 -20 10 -36 0 -17 6 -32 15
-35 8 -4 15 -12 15 -20 0 -24 18 -15 34 18 21 40 20 46 -2 21 -14 -17 -19 -18
-30 -7 -6 7 -12 28 -12 45 0 18 -3 29 -7 26 -3 -4 -12 -2 -20 4 -9 8 -13 8
-13 1z"/>
<path d="M3188 3273 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2474 3259 c-4 -7 -2 -15 4 -17 7 -2 12 -11 12 -20 0 -12 3 -13 11
-5 8 8 7 17 -4 33 -11 16 -17 18 -23 9z"/>
<path d="M2477 3059 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M2616 3022 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
<path d="M5566 2992 c-12 -23 -13 -32 -3 -35 7 -4 6 -6 -4 -6 -9 -1 -19 -10
-22 -21 -5 -19 -4 -19 19 -2 15 11 21 23 18 35 -3 10 0 25 7 33 6 8 9 17 6 20
-3 3 -13 -8 -21 -24z"/>
<path d="M2541 2940 c0 -14 3 -31 8 -39 8 -12 12 -11 28 4 17 18 27 55 14 55
-3 0 -13 -10 -20 -22 l-15 -23 -7 25 -7 25 -1 -25z"/>
<path d="M1660 2380 l0 -160 126 0 125 0 -3 28 c-3 27 -3 27 -90 30 l-88 3 0
34 0 35 75 0 75 0 0 30 0 30 -75 0 -75 0 0 35 0 35 85 0 85 0 0 30 0 30 -120
0 -120 0 0 -160z"/>
<path d="M1970 2380 l0 -160 115 0 115 0 0 30 0 30 -80 0 -80 0 0 130 0 130
-35 0 -35 0 0 -160z"/>
<path d="M2250 2380 l0 -160 35 0 35 0 0 90 c0 50 3 90 8 89 4 0 40 -40 80
-90 62 -75 78 -89 103 -89 l29 0 0 160 0 160 -35 0 -35 0 0 -97 0 -98 -28 33
c-15 18 -50 61 -77 97 -43 55 -55 64 -82 64 l-33 1 0 -160z"/>
<path d="M2620 2425 c0 -165 27 -205 140 -205 66 0 105 21 130 70 18 35 20 56
18 143 l-3 102 -37 3 -38 3 0 -113 c0 -62 -4 -118 -8 -125 -13 -21 -60 -33
-86 -23 -38 14 -46 40 -46 154 l0 106 -35 0 -35 0 0 -115z"/>
<path d="M2980 2380 l0 -160 35 0 35 0 0 93 0 92 39 -64 c22 -35 47 -71 56
-80 15 -15 18 -14 37 9 11 14 35 50 54 80 l33 55 1 -92 0 -93 35 0 35 0 0 161
0 160 -32 -3 c-28 -3 -37 -13 -87 -95 -31 -51 -58 -93 -61 -93 -3 0 -31 43
-63 95 -52 85 -62 95 -88 95 l-29 0 0 -160z"/>
<path d="M3485 2464 c-19 -42 -35 -78 -35 -80 0 -2 -16 -37 -35 -78 -19 -41
-35 -78 -35 -81 0 -3 16 -5 36 -5 31 0 37 4 50 35 l15 36 77 -3 77 -3 10 -32
c8 -30 13 -33 48 -33 47 0 48 -9 -37 182 l-61 138 -37 0 c-37 0 -38 -1 -73
-76z m95 -50 c11 -26 20 -50 20 -55 0 -5 -20 -9 -45 -9 -25 0 -45 3 -45 6 0 8
42 104 46 104 2 0 12 -21 24 -46z"/>
<path d="M3955 2520 c-37 -12 -83 -53 -96 -87 -14 -37 -11 -101 7 -139 16 -33
57 -65 102 -79 31 -9 102 2 126 20 28 21 14 28 -20 10 -61 -32 -149 -9 -184
47 -12 20 -20 50 -20 78 0 109 105 172 206 124 42 -20 55 -13 19 10 -31 20
-100 28 -140 16z"/>
<path d="M4255 2515 c-67 -31 -95 -73 -95 -140 0 -137 149 -212 257 -129 103
78 77 232 -46 273 -44 15 -79 13 -116 -4z m129 -21 c70 -34 97 -136 54 -201
-80 -116 -258 -63 -258 77 0 107 105 171 204 124z"/>
<path d="M4570 2369 c0 -194 18 -209 22 -19 2 92 6 120 13 105 16 -33 117
-205 120 -205 2 0 32 51 67 113 l63 112 3 -125 c4 -191 22 -175 22 21 -1 190
6 189 -94 17 l-61 -107 -68 119 c-38 66 -72 122 -78 125 -5 4 -9 -57 -9 -156z"/>
<path d="M1710 1875 c0 -58 3 -75 14 -75 9 0 16 12 18 28 2 22 9 28 36 32 45
7 45 24 0 28 -54 5 -51 30 5 34 60 4 48 22 -18 26 l-55 3 0 -76z"/>
<path d="M1855 1883 c-16 -36 -31 -70 -33 -75 -2 -4 6 -8 17 -8 12 0 21 6 21
15 0 11 11 15 40 15 27 0 43 -5 46 -15 5 -12 44 -23 44 -12 0 2 -16 36 -35 75
-45 93 -60 93 -100 5z m62 -19 c-3 -3 -12 -4 -20 -2 -11 2 -12 9 -5 28 l10 25
11 -23 c5 -12 8 -25 4 -28z"/>
<path d="M2018 1936 c-32 -24 -22 -53 22 -68 51 -17 51 -34 0 -31 -52 2 -55
-23 -4 -33 52 -10 86 9 82 44 -2 22 -10 29 -40 36 -50 13 -52 40 -3 34 28 -3
35 -1 33 11 -4 21 -67 26 -90 7z"/>
<path d="M2130 1935 c0 -9 9 -15 25 -15 24 0 25 -3 25 -60 0 -53 2 -60 20 -60
18 0 20 7 20 59 0 52 3 60 20 63 47 7 21 23 -42 26 -56 2 -68 0 -68 -13z"/>
<path d="M2365 1880 c-19 -38 -35 -72 -35 -75 0 -3 9 -5 20 -5 11 0 20 7 20
15 0 22 76 21 88 -1 5 -9 16 -14 24 -13 13 3 10 17 -16 76 -40 94 -55 95 -101
3z m59 -18 c-25 -6 -32 2 -22 28 l9 25 14 -25 c12 -21 12 -25 -1 -28z"/>
<path d="M2520 1875 c0 -60 3 -75 15 -75 11 0 15 11 15 40 0 22 3 40 7 40 3 0
21 -18 40 -40 52 -60 63 -54 63 36 0 66 -2 75 -17 72 -13 -2 -18 -13 -18 -35
0 -47 -9 -48 -45 -3 -48 61 -60 54 -60 -35z"/>
<path d="M2700 1875 l0 -75 49 0 c64 0 101 28 101 75 0 47 -37 75 -101 75
l-49 0 0 -75z m111 24 c19 -36 -3 -69 -46 -69 -35 0 -35 0 -35 45 0 45 0 45
35 45 25 0 37 -6 46 -21z"/>
<path d="M2940 1875 c0 -60 3 -75 15 -75 8 0 15 9 15 20 0 13 7 20 20 20 11 0
25 -9 32 -20 7 -11 21 -20 32 -20 17 0 17 2 3 24 -14 21 -14 26 -2 36 8 7 15
23 15 36 0 37 -26 54 -81 54 l-49 0 0 -75z m93 31 c7 -19 -12 -36 -40 -36 -18
0 -23 6 -23 25 0 21 5 25 29 25 16 0 31 -6 34 -14z"/>
<path d="M3100 1875 l0 -75 61 0 c44 0 60 3 57 13 -3 6 -23 13 -46 15 -54 4
-60 32 -7 32 24 0 35 5 35 15 0 10 -11 15 -35 15 -24 0 -35 5 -35 15 0 11 11
15 40 15 29 0 40 4 40 15 0 12 -13 15 -55 15 l-55 0 0 -75z"/>
<path d="M3263 1877 c-27 -61 -30 -73 -16 -76 9 -1 20 4 25 13 6 10 22 16 43
16 21 0 37 -6 43 -16 5 -9 16 -14 25 -13 13 3 10 16 -18 77 -44 95 -60 95
-102 -1z m61 -15 c-25 -6 -32 2 -22 28 l9 25 14 -25 c12 -21 12 -25 -1 -28z"/>
<path d="M3420 1875 l0 -75 54 0 c36 0 60 5 70 16 18 17 21 40 6 49 -5 3 -7
19 -4 34 8 36 -16 51 -80 51 l-46 0 0 -75z m90 30 c0 -10 -10 -15 -30 -15 -20
0 -30 5 -30 15 0 10 10 15 30 15 20 0 30 -5 30 -15z m10 -60 c0 -10 -11 -15
-35 -15 -24 0 -35 5 -35 15 0 10 11 15 35 15 24 0 35 -5 35 -15z"/>
<path d="M3590 1875 l0 -75 55 0 c42 0 55 3 55 15 0 11 -11 15 -40 15 l-40 0
0 60 c0 47 -3 60 -15 60 -12 0 -15 -15 -15 -75z"/>
<path d="M3720 1875 l0 -75 60 0 c47 0 60 3 60 15 0 11 -12 15 -45 15 -33 0
-45 4 -45 15 0 10 10 15 34 15 49 0 57 28 9 32 -53 4 -54 26 -2 30 70 5 61 28
-10 28 l-61 0 0 -75z"/>
<path d="M3920 1944 c0 -5 10 -39 23 -76 27 -83 51 -89 76 -20 8 23 18 42 21
42 3 0 11 -21 18 -46 10 -38 16 -45 33 -42 17 2 27 19 45 73 13 39 24 71 24
73 0 1 -8 2 -18 2 -13 0 -21 -13 -30 -45 -7 -25 -16 -45 -21 -45 -4 0 -14 20
-21 45 -9 32 -18 45 -30 45 -12 0 -22 -14 -31 -45 -7 -25 -17 -45 -20 -45 -4
0 -14 19 -21 43 -8 23 -22 43 -31 45 -9 2 -17 0 -17 -4z"/>
<path d="M4190 1875 l0 -75 61 0 c44 0 60 3 57 13 -3 6 -23 13 -46 15 -53 4
-60 32 -8 32 21 0 36 6 40 15 4 12 -3 15 -34 15 -58 0 -55 28 3 32 60 4 48 22
-18 26 l-55 3 0 -76z"/>
<path d="M4340 1875 c0 -60 3 -75 15 -75 8 0 15 9 15 20 0 15 7 20 28 20 38 0
72 26 72 55 0 33 -36 55 -87 55 l-43 0 0 -75z m90 20 c0 -21 -5 -25 -30 -25
-25 0 -30 4 -30 25 0 21 5 25 30 25 25 0 30 -4 30 -25z"/>
<path d="M4506 1934 c-30 -29 -16 -52 47 -74 41 -14 23 -33 -24 -27 -52 7 -54
-19 -3 -29 52 -10 86 9 82 44 -2 22 -10 29 -40 36 -50 13 -52 40 -3 34 28 -3
35 -1 33 11 -5 23 -71 27 -92 5z"/>
<path d="M4640 1875 c0 -60 3 -75 15 -75 12 0 15 15 15 75 0 60 -3 75 -15 75
-12 0 -15 -15 -15 -75z"/>
<path d="M4690 1935 c0 -9 9 -15 25 -15 24 0 25 -3 25 -60 0 -53 2 -60 20 -60
18 0 20 7 20 59 0 52 3 60 20 63 47 7 21 23 -42 26 -56 2 -68 0 -68 -13z"/>
<path d="M4850 1875 l0 -75 61 0 c44 0 60 3 57 13 -3 6 -23 13 -46 15 -54 4
-58 29 -5 34 52 4 56 28 4 28 -30 0 -41 4 -41 15 0 11 11 15 40 15 29 0 40 4
40 15 0 12 -13 15 -55 15 l-55 0 0 -75z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
